<template>
  <div id="app1">
    <div class="back">
       <img src="../assets/beijing@2x.png" alt="">
    </div>
    <div class="content">
      <div class="topName">
        <img class="leftImg" v-if="companyAvatar" :src="companyAvatar" alt="">
        <img class="leftImg" v-else src="../assets/nologo@2x.png" alt="">
        <div class="rightContent">
          <div class="fontS_17 text_overflow_1">{{companyName}}</div>
          <div class="rightUserInfo">
            <img  src="../assets/xiaoren-icon@2x.png" alt="">
            <span>{{companyMan}}</span>
          </div>
        </div>
      </div>
      <div class="model1">
        <router-link to="/setcompanyInfo" class="modelItem">
          <div>
            <img style="width:1.5rem;vertical-align: middle;margin-right:0.4rem;" src="../assets/qiyexinxi-icon@2x.png" alt="">
            <span>企业信息</span>
          </div>
          <van-icon size="15" color="#A5ABB6" name="arrow" />
        </router-link>
        <router-link to="/myCompany" class="modelItem"  >
          <div>
            <img style="width:1.5rem;vertical-align: middle;margin-right:0.4rem;" src="../assets/wodeqiye-icon@2x.png" alt="">
            <span>我的企业</span>
          </div>
          <van-icon size="15" color="#A5ABB6" name="arrow" />
        </router-link>
        <router-link  to="/about" class="modelItem">
          <div>
            <span>关于萝卜猎手投后服务</span>
          </div>
          <van-icon size="15" color="#A5ABB6" name="arrow" />
        </router-link>
         <div class="modelItem" @click="logout">
          <div >
            <span>退出登录</span>
          </div>
          <van-icon size="15" color="#A5ABB6" name="arrow" />
        </div>
    </div> 
    </div> 
    
    
  </div>
</template>
<script>
import {mapState,mapActions} from "vuex"
import { Toast } from 'vant';
export default {
  data(){
    return {

    }
  },
  computed:{
    ...mapState({
      userInfo:  (state) => state.userInfo,
      companyName: (state) => state.companyName,
      companyAvatar: (state) => state.companyAvatar,
      companyMan: (state) => state.companyMan,
    }),
  },
  methods:{
     logout(){
      this.$store.commit("SET_REPORT_ITEM","")
      this.$store.commit("SET_USER_INFO","") 
      this.$store.commit("SET_SCORE_ITEM","")
      Toast.success("退出成功！")
      setTimeout(()=>{
        this.$router.push("/")
      },1000)
     }, 
  }
}
</script>
<style scoped>
#app1{
  /* width:100%; */
  background-color: #fff;
  height:100vh;
   
}
.back{
  position: relative;
  width: 100%;
}
.content{
  
  position: absolute;
  top:5rem;
  left:9%;
}
.topName{
  display: flex;
  align-items: center;
}
.leftImg{
  width:4rem;
  height:4rem;
  margin-right:1rem;
  border-radius: 50%;
}
.rightUserInfo{
  margin-top:0.5rem;
}
 .rightContent{
   width:19rem;
 }
.rightContent img {
  width:1.5rem;
  vertical-align: middle;
  margin-right:0.5rem;
}
.model1{
  width:100%;
  background: #fff;
   
  padding:2.5rem 1rem;
  border-radius: 0.4rem;
 
  /* margin:0 auto; */
  margin-top:2rem;
}
.modelItem{
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom:0.1rem solid #E9E9E9;
  padding-bottom:1rem;
  margin-bottom:1.2rem;
  
}
</style>